import './guests.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { TableContextProvider, useTableControls } from '../../../../common/components/table/table-context';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import { IEIQConnectRead, webGuestsListConfig } from './guests.config';
import { EiqConnectList } from './eiq-connect-list/eiq-connect-list';
import { EiqConnectAddEdit } from './eiq-connect-guests-add-edit/eiq-connect-add-edit';
import { EiqConnectFilter } from './eiq-connect-filter/eiq-connect-filter';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { EiqConnectDetails } from './eiq-connect-details/eiq-connect-details';
import { EiqConnectShare } from './eiq-connect-share/eiq-connect-share';
import eiqConnectStore from '../../store/eiq-connect.store';
import { observer } from 'mobx-react-lite';
import { IResidentAddresses } from '../../../../common/services/residents-service/residents.service.types';
import { StrToDateTimeLocal } from '../../../../common/helpers/date.helper';
import { mapToGuestValidity } from '../../../../common/helpers/guest-validity.helper';
import { guestTypeMap } from 'common/constans/guest-type-options.constant';
import configurationStore from 'common/stores/configuration/configuration.store';
import { SortBy } from 'common/components/table/table.types';
import { guestSortKeys, Sorters } from './eiq-connect-filter/eiq-connect-sorters.consts';
import { useModal } from 'common/hooks/use-modal/use-modal.hook';
import ConfirmationModal from 'features/eiq-connect/components/confirmation-modal/confirmation-modal';
import fastAccessService from 'common/services/fast-access-service/fast-access-service';
import { ICreateGuestFastAccessPass } from 'common/services/fast-access-service/fast-access-service.types';
import { Share } from '@capacitor/share';

const selectAll = 'SelectAll';
const defaultSortBy: SortBy = Sorters[guestSortKeys.latestAdditionKey];

export const Guests = observer(() => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const defaultConfiguration = configurationStore.configuration?.default;
  const shareModal = useModal({});

  const [guestFastAccessPassModel, setGuestFastAccessPassModel] = useState<ICreateGuestFastAccessPass>();

  const data = useMemo(() => {
    const selectedAddresses = eiqConnectStore.selectedAddressesId;

    const isAll = !selectedAddresses || selectedAddresses?.length === 0 || selectedAddresses?.[0] === selectAll;

    if (isAll) {
      return eiqConnectStore.residentDetails?.addresses?.reduce(
        (acc, curr) =>
          acc.concat(
            curr.guests.map((guest) => {
              return {
                ...guest,
                validity: mapToGuestValidity(guest.validity, StrToDateTimeLocal(guest?.visitDate)),
                typeToSearch: guestTypeMap[guest.type],
                address: `${curr.address.streetNumber} ${curr.address.street.name} `,
                addressId: curr.address.id,
              };
            }) as any,
          ),
        [],
      );
    } else if (selectedAddresses?.length > 0) {
      const guests: IEIQConnectRead[] = [];

      selectedAddresses?.forEach((value) => {
        const index = value as number;
        const currentAddress = JSON.parse(JSON.stringify(eiqConnectStore.residentDetails?.addresses[index])) as IResidentAddresses;

        if (!currentAddress) {
          return;
        }
        const guest = currentAddress.guests.map((guest) => {
          return {
            ...guest,
            validity: mapToGuestValidity(guest.validity, StrToDateTimeLocal(guest?.visitDate)),
            typeToSearch: guestTypeMap[guest.type],
            address: `${currentAddress.address.streetNumber} ${currentAddress.address.street.name} `,
            addressId: currentAddress.address.id,
          };
        });
        guests.push(...guest);
      });

      return guests;
    }

    return [];
  }, [eiqConnectStore.residentDetails, eiqConnectStore.selectedAddressesId]);

  const tableControls = useTableControls({
    onSelectedRow: (row: any) => {
      if (isMobile) {
        navigate(`/eiq-connect/guests/${row?.id}/view`);
      }
    },
    defaultSort: defaultSortBy,
    clientSide: true,
    data: data,
    initialColumns: webGuestsListConfig,
  });

  useEffect(() => {
    if (eiqConnectStore.guestFilters) {
      tableControls.setSortBy(Sorters[eiqConnectStore.guestFilters.sortBy]);
    }
  }, []);

  useEffect(() => {
    if (isMobile) {
      tableControls.hideColumns({
        validity: false,
        daysOfWeek: false,
        action: false,
        arrow: true,
      });
    } else {
      tableControls.hideColumns({
        validity: true,
        daysOfWeek: true,
        action: true,
        arrow: false,
      });
    }
  }, [isMobile]);

  const handleShare = async () => {
    if (!guestFastAccessPassModel) {
      return;
    }

    const result = await fastAccessService.createGuestFastAccessPass(guestFastAccessPassModel);

    if (!configurationStore.configuration?.default.communityName) {
      await configurationStore.requestConfiguration();
    }

    Share.share({
      title: 'Share',
      text: `Here’s your ${configurationStore.configuration?.default.communityName} entry QR code. Please remember to bring your photo ID.`,
      url: result.url,
      dialogTitle: 'Share',
    });

    shareModal.hideModal();
  };

  const location = useLocation();
  const isBasePath = location.pathname.endsWith('/guests');
  const isSearchPath = location.pathname.endsWith('/guests/search');

  return (
    <TableContextProvider controls={tableControls}>
      {(isBasePath || isSearchPath) && <EiqConnectList className={isBasePath || isSearchPath ? null : 'eiq-display-none'} />}
      <Routes>
        <Route path="filter" element={<EiqConnectFilter />} />
        <Route
          path="add"
          element={<EiqConnectAddEdit shareFastAccessModal={shareModal} setGuestFastAccessPassModel={setGuestFastAccessPassModel} />}
        />
        {!defaultConfiguration?.isShareGuestConnect && <Route path="share" element={<EiqConnectShare />} />}
        <Route
          path="/:id/edit"
          element={<EiqConnectAddEdit shareFastAccessModal={shareModal} setGuestFastAccessPassModel={setGuestFastAccessPassModel} />}
        />
        <Route path="/:id/view" element={<EiqConnectDetails />} />
      </Routes>
      <ConfirmationModal
        title="Share"
        isOpen={shareModal?.isOpenModal}
        onClose={shareModal?.hideModal}
        mainText={'Would you like to send a QR code now?'}
        handleConfirm={handleShare}
        confirmBtnText={'Yes'}
        confirmBtnIcon={''}
      />
    </TableContextProvider>
  );
});
