import './unsubscribe.scss';
import { Button } from 'antd';
import LogoIcon from 'assets/icons/eiq-connect/eiq-connect-logo.png';
import { NotificationType } from 'common/enums/notification-type.enum';
import communityResolverService from 'common/services/community-resolver-service/community-resolver.service';
import residentsService from 'common/services/residents-service/residents.service';
import { IUnsubscribeEmail, IUnsubscribeSMS } from 'common/services/residents-service/residents.service.types';
import urlService from 'common/services/url-service/url-service';
import { useEffect, useState } from 'react';

export const Unsubscribe = () => {
  const [source, setSource] = useState<string>('');
  const [channel, setChannel] = useState<NotificationType>(NotificationType.Undefined);
  const [communityId, setCommunityId] = useState<string>('');
  const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);

  const replaceSpaces = (urlParam: string) => {
    return decodeURIComponent(urlParam).replace(/ /g, '+');
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sourceParam = searchParams.get('source');
    const channelParam = searchParams.get('channel');
    const communityIdParam = searchParams.get('communityId');

    if (sourceParam) {
      setSource(replaceSpaces(sourceParam));
    }
    if (channelParam && Object.values(NotificationType).includes(channelParam.toLowerCase() as NotificationType)) {
      setChannel(channelParam as NotificationType);
    }
    if (communityIdParam) {
      setCommunityId(communityIdParam);
    }
  }, []);

  const unsubscribeEmail = async (source: string) => {
    if (!communityId) {
      return;
    }
    const unsubscribeEmail: IUnsubscribeEmail = {
      Email: source,
    };

    const url = await communityResolverService.resolveByCommunityId(communityId);

    await urlService.withTemporaryBaseUrl(url, residentsService.unsubscribeFromMassEmail, unsubscribeEmail);
    setIsUnsubscribed(true);
  };

  const unsubscribeSMS = async (source: string) => {
    if (!communityId) {
      return;
    }

    const unsubscribeSMS: IUnsubscribeSMS = {
      Number: source,
    };

    const url = await communityResolverService.resolveByCommunityId(communityId);

    await urlService.withTemporaryBaseUrl(url, residentsService.unsubscribeFromMassSMS, unsubscribeSMS);

    setIsUnsubscribed(true);
  };

  const handleClick = async () => {
    try {
      switch (channel.toLowerCase()) {
        case NotificationType.Email: {
          await unsubscribeEmail(source);
          break;
        }
        case NotificationType.SMS: {
          await unsubscribeSMS(source);
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.error('Error during unsubscribe:', error);
    }
  };

  return (
    <div className="eiq-unsubscribe-page">
      <div className="eiq-wrapper">
        <div className="eiq-content">
          <div className="logo-wrapper">
            <img className="logo" src={LogoIcon} alt="logo" />
          </div>
          {!isUnsubscribed ? (
            <>
              <p>Are you sure you want to unsubscribe?</p>
              <Button onClick={handleClick} className="unsubscribe-btn" type="primary">
                Unsubscribe
              </Button>
            </>
          ) : (
            <p>Unsubscription successful. We’re sorry to see you go.</p>
          )}
        </div>
      </div>
    </div>
  );
};
